function OpenModal(url) {
  const modal = $('#newsModal-0');

  const body = $(modal.find(".modal-body")[0]);
  body.html("");

  fetch(`/views${url}`).then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.text();
  })
    .then((response) => {
      body.append(response);
    });

  modal.modal('show');
}

window.OpenContainerLastUrl = "";

function OpenContainer(url) {

  if (url == '/pt/inicio') {
    $("#containerOT div.open").slideToggle().removeClass();
    $("#overlay").fadeOut(600);
    return;
  }
    const body = $("#section-container");
    if (window.OpenContainerLastUrl == url) {
      body.slideToggle().removeClass();
      $("#overlay").fadeOut(600);
      window.OpenContainerLastUrl = "";
      return;
    }
    body.html("");

    if (body.hasClass("open")) {
      body.slideToggle().removeClass();
      $("#overlay").fadeOut(600);
    }
    window.OpenContainerLastUrl = url;
    fetch(`/views${url}`).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return response.text();
    })
      .then((response) => {

        body.append(response);

        if (body.hasClass("open")) {
          body.slideToggle().removeClass();
          $("#overlay").fadeOut(600);
        } else {
          $("#containerOT div.open").slideToggle().removeClass();
          body.slideToggle().addClass("open");
          $("#overlay").fadeIn(600);
        }

      });



}

$("#overlay").on("click", function () {
  $("#containerOT div.open").slideToggle().removeClass();
  $("#overlay").fadeOut(600);
});

window.OpenContainer = OpenContainer;
window.OpenModal = OpenModal;
